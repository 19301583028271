var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"OrderDetails"},[_c('div',{staticClass:"Details_box"},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.$router.push({
          name: 'OrderCenter',
          params: {
            page: _vm.page * 1,
          },
        })}}},[_vm._v(" ﹤ 订单中心 ")]),_c('div',{staticClass:"main_body"},[_c('div',{staticClass:"main_top_box"},[_c('div',{staticClass:"serial_box"},[_vm._v(" 订单编号: "),_c('span',[_vm._v(" "+_vm._s(_vm.itemObj.orderNumber)+" ")])]),_c('div',{staticClass:"place_date_box"},[_vm._v("下单时间")]),_c('div',{staticClass:"number_box"},[_vm._v("数量")]),_c('div',{staticClass:"money_box"},[_vm._v("金额")])]),_vm._l((_vm.itemObj.productInfos),function(item,index){return _c('div',{key:index,staticClass:"main_bottom_box"},[_c('div',{staticClass:"serial_box"},[_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":item.productFiles
                  ? _vm.$store.state.onlineBasePath + item.productFiles
                  : require('assets/images/no-shop-img80.png'),"alt":""}})]),_c('div',{staticClass:"item_name"},[_vm._v(_vm._s(item.productName))])]),_c('div',{staticClass:"place_date_box"},[_vm._v(" "+_vm._s(_vm.itemObj.createTime.substring(0, 10))+" ")]),_c('div',{staticClass:"number_box"},[_vm._v(_vm._s(item.productNum))]),_c('div',{staticClass:"money_box"},[_vm._v("¥ "+_vm._s(_vm.itemObj.orderPrice))])])})],2),_c('div',{staticClass:"bottom_part"},[_c('div',{staticClass:"bottom_box"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"item_box"},[_vm._v("支付时间:")]),_c('div',{staticClass:"item"},[_vm._v(_vm._s(_vm.itemObj.payedTime))])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"item_box"},[_vm._v("商品总额:")]),_c('div',{staticClass:"item"},[_vm._v("¥ "+_vm._s(_vm.itemObj.orderPrice))])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"item_box"},[_vm._v("限时优惠:")]),_c('div',{staticClass:"item"},[_vm._v(" - ¥ "+_vm._s(( _vm.itemObj.orderPrice - (_vm.itemObj.payPrice == null ? _vm.itemObj.orderPrice : _vm.itemObj.payPrice) ).toFixed(2))+" ")])]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"item_red_box"},[_vm._v("实付数:")]),_c('div',{staticClass:"item_red"},[_vm._v(" ¥ "+_vm._s(_vm.itemObj.payPrice == null ? _vm.itemObj.orderPrice : _vm.itemObj.payPrice)+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }